<template>
    <el-main class="resetPasswordSuccess" v-if="passwordResetted">
        Dit kodeord er nu blevet opdateret.
        <p>Du kan nu logge ind på din bruger med dit nye kodeord.</p>
    </el-main>
    <div class="resetPassword">
        <el-main v-if="!passwordResetted">
        Nulstil adgangskode
            <el-form>
                <el-form-item>
                <el-input type="password" v-model="newPassword" placeholder="Kodeord" show-password>
                    <template #prepend>Nyt kodeord</template>
                </el-input>
                <span class="input-info">Adgangskode skal indeholde mindst 8 tegn og tal</span>
                </el-form-item>
                <el-form-item>
                <el-input type="password" v-model="confirmPassword" placeholder="Kodeord" @keyup.enter="resetPassword" show-password>
                    <template #prepend>Gentag kodeord</template>
                </el-input>
                <span class="input-error" v-if="validationFailed">Kodeord overholder ikke krav eller er ikke ens. Prøv venligst igen.</span> 
                </el-form-item>
                <el-form-item>
                <el-button class="" @click="resetPassword">Nulstil kodeord</el-button>
                </el-form-item>
            </el-form>
        </el-main>
    </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Resetpassword',
  data() {
      return {
          newPassword: "",
          confirmPassword: "",
          token: "",
          email: "",
          validationFailed: false,
          passwordResetted: false,
      }
  },
  mounted() {
    this.token = decodeURIComponent((new RegExp('[?|&]' + 'token' + '=' + '([^&;]+?)(&|#|;|$)').exec(location.hash)||[""])[1].replace(/\+/g, '%20'));
    this.email = decodeURIComponent((new RegExp('[?|&]' + 'email' + '=' + '([^&;]+?)(&|#|;|$)').exec(location.hash)||[""])[1].replace(/\+/g, '%20'));
  },
  methods: {
      resetPassword() {
          this.validatePassword();
          if(!this.validationFailed) {
            axios.post(`${process.env.VUE_APP_BACKEND_URL}Auth/ResetPassword`, {
                "email": this.email,
                "newPassword": this.newPassword,
                "confirmNewPAssword": this.confirmPassword,
                "token": this.token
            }).then((response) => {
                if(response.data.data.isSuccess) {
                    this.passwordResetted = true;
                }
            });
          }
      },
      validatePassword() {
          this.validationFailed = false;

          if(this.newPassword.length < 8 || !this.validatePasswordString(this.newPassword))
          {
              this.validationFailed = true;
          }
          else if(this.confirmPassword.length < 8 || !this.validatePasswordString(this.confirmPassword))
          {
              this.validationFailed = true;
          }
      },
      validatePasswordString(password) {
        const re = /\d/;
        return re.test(password);
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.resetPassword {
    width: 400px;
    margin: 0 auto;
}

.input-info {
    float: left;
    width: 100%;
    font-size: 12px;
}

.input-error {
    float: left;
    width: 100%;
    font-size: 12px;
    color: rgb(255, 85, 85);
}

.resetPasswordSuccess {
    text-align: center;
    font-size: 24px;
    color: #9433ff;
}

.resetPasswordSuccess p {
    font-size: 18px;
    color: black;
}

</style>
